import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { ProcessFileType, TProcessState } from '../../types/Process';
import { simpleDateFormat } from '../../Helpers';
import Image from '../cms/Image';
import Description from './panels/Description';
import { fetchWithAuthentication } from '../../actions/BaseAction';
import Planning from './panels/Planning';
import Execution from './panels/Execution';
import Chemical from './panels/Chemical';
import UpdateDate from './panels/UpdateDate';

interface IProcessProps {
  process: TProcessState;
  expandedDescription: boolean;
  expandedPlanning: boolean;
  expandedExecution: boolean;
  expandedChemical: boolean;
  expandedRevised: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const Process: React.FC<IProcessProps> = ({
  process,
  expandedDescription,
  expandedPlanning,
  expandedExecution,
  expandedChemical,
  expandedRevised,
  handleChange,
}): JSX.Element => {
  const [urlCache, setUrlCache] = useState<{[key: string]: string}>({});
  const getUpdateDate = (date: Date) => {
    return simpleDateFormat(date);
  };

  const handleFileClick = (filePath: string) => () => {
    if (urlCache[filePath]) {
      const cachedBlob = urlCache[filePath];

      window.open(cachedBlob, '_blank');

      return;
    }

    fetchWithAuthentication(filePath)
      .then(blob => {
        if (blob !== null) {
          const blobUrl = URL.createObjectURL(blob);

          setUrlCache((prevCache) => ({ ...prevCache, [filePath]: blobUrl }));

          window.open(blobUrl, '_blank');
        }
      })
      .catch(error => {throw  error});
  }

  return (
    <>
      {process && (
        <>
          {process.activeProcess?.image && (
            <Image
              image={{
                url: process.activeProcess?.image as string,
                name: process.activeProcess?.name as string,
              }}
            />
          )}
        </>
      )}

      {process.activeProcess?.description && (
        <Description
          description={process.activeProcess.description as string}
          expanded={expandedDescription}
          handleChange={handleChange}
          handleFileClick={handleFileClick}
          processFiles={
            Object.values(process.activeProcess.processFiles).filter(file => file.type === ProcessFileType.DESCRIPTION)
          }
        />
      )}

      {process.activeProcess?.planning && (
        <Planning
          planning={process.activeProcess.planning as string}
          expanded={expandedPlanning}
          handleChange={handleChange}
          handleFileClick={handleFileClick}
          processFiles={
            Object.values(process.activeProcess.processFiles).filter(file => file.type === ProcessFileType.PLANNING)
          }
        />
      )}

      {process.activeProcess?.execution && (
        <Execution
          execution={process.activeProcess.execution as string}
          expanded={expandedExecution}
          handleChange={handleChange}
          handleFileClick={handleFileClick}
          processFiles={
            Object.values(process.activeProcess.processFiles).filter(file => file.type === ProcessFileType.EXECUTION)
          }
        />
      )}

      {process.activeProcess?.chemicals && process.activeProcess?.chemicals.length > 0 && (
        <Chemical
          chemicals={process.activeProcess.chemicals}
          expanded={expandedChemical}
          handleChange={handleChange}
        />
      )}

      {process.activeProcess?.updateDate && (
        <UpdateDate
          updateDate={
            getUpdateDate(
              process.activeProcess.updateDate
            ) as string
          }
          expanded={expandedRevised}
          handleChange={handleChange}
        />
      )}
    </>
  );
}

export default Process;